<template>
  <div>
    <div class="container">
      <img src="@/assets/img/mock/MockInterview/img1.png" alt="" />
      <img src="@/assets/img/mock/MockInterview/img2.png" alt="" />
      <img src="@/assets/img/mock/MockInterview/img3.png" alt="" />
      <img src="@/assets/img/mock/MockInterview/img4.png" alt="" />
      <img src="@/assets/img/mock/MockInterview/img5.png" alt="" />
    </div>
	<div class="prices">
	  <span class="usdPrice">${{ classInfo.usdAmount }}/</span>
	  <span class="rmbPrice">￥{{ classInfo.amount }} </span>
	  <div class="buy" @click="provideDialogVisible = true">立即购买</div>
	</div>
	<el-dialog title="选择面试类型" :visible.sync="provideDialogVisible" width="calc(100vw - 30px)" append-to-body>
	  <div class="provideContainer">
	    <el-button
	      class="provideBtn"
	      v-for="(btn, index) in classInfo.details"
	      :key="index"
	      @click="chooseCombo(btn)"
	      >{{ btn.courseName }}</el-button
	    >
	  </div>
	</el-dialog>
  </div>
</template>
<script>
import mockMix from "@/utils/mockMix";
import { setStorageForArray } from "@/utils/index";
export default {
  name: "MockInterview",
  data() {
    return {
		provideDialogVisible:false
	};
  },
  mounted() {},
  mixins: [mockMix],
  methods: {
	  chooseCombo(item) {
		setStorageForArray("order", [item]);
		this.$router.push("/pay/confirm");
	  },
  },
};
</script>
<style scoped lang="scss">
	@import url("../../assets/css/course.scss");
.container {
	padding-top: 54px;
  img {
    width: 100%;
    vertical-align: top;
  }
}

.provideBtn {
  width: 80%;
  margin: 0 10%;
}
.provideContainer .el-button {
  margin-bottom: 24px;
  &:hover {
    background: #0a7aff;
    color: #fff;
  }
}
</style>