<template></template>

<script>
import { getMockDetail } from "@/service/mock";
import { setStorage, setStorageForArray } from "./common";

export default {
  name: "courseMix",
  data() {
    return {
      courseId: "",
      classInfo: {},
      comboData: [],
      filterData: [],
      selectCombo: null,
    };
  },
  mounted() {
    const path = this.$route.path;
    if (path && path.indexOf("/mock/") > -1) {
      this.courseId = path.replace("/mock/", "");
      this.getClassPriceInfo();
    } else {
      this.$message.error("模拟课程数据错误");
    }
  },
  methods: {
    getClassPriceInfo() {
      getMockDetail(this.courseId)
        .then((res) => {
          if (!res.result.code) {
            this.classInfo = res.result;
          }
        })
        .catch((err) => {
          console.log("courseInfo", err);
        });
    },
    buyCombo() {
      if (
        !this.classInfo ||
        !this.classInfo.details ||
        this.classInfo.details.length === 0
      ) {
        this.$message.error("套餐数据错误");
        return;
      }
      try {
        if (!this.selectCombo) {
          this.selectCombo = this.classInfo.details[0];
        }
      } catch (e) {
        this.$message.error("套餐数据错误");
        return;
      }
      let cart = {
        imgUrl: this.selectCombo.cover,
        objectName: this.selectCombo.courseName,
        objectId: this.selectCombo.id,
        price: this.selectCombo.usdAmount || 0,
        realPrice: this.selectCombo.usdAmount,
      };
      console.log(cart, this.selectCombo,'selectComboselectComboselectComboselectCombo');
      setStorage("realPrice", this.selectCombo.usdAmount || 0);
      setStorage("totalprice", this.selectCombo.usdAmount || 0);
      setStorageForArray("orderSelect", [cart]);

      this.$router.push("/pay/confirm?type=3");
    },
    unique(arr) {
      let newArr = [arr[0]];
      for (let i = 1; i < arr.length; i++) {
        let repeat = false;
        for (let j = 0; j < newArr.length; j++) {
          if (arr[i].name === newArr[j].name) {
            repeat = true;
            break;
          } else {
          }
        }
        if (!repeat) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
  },
};
</script>

<style scoped>
</style>
